
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Form.css';
import V1 from '../authbg.mp4';
import HOST from "../host";
import { GlobalContext } from "../../App";
import { logToServer } from "../logger";
import MapboxExample from "../Authforms/MapExample"
import files from "../static";
export default function SignUp() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { getCsrfToken } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    username: '',
    country_code: '',
    number: "",
    member_email: "",
    password: "",
    cpassword: '',
    type: "user",
    org_name: "",
    org_website: "",
    org_add: "",
    org_plan: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [loader, setLoader] = useState(false);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$])[a-zA-Z\d@#$]{8,}$/;

  useEffect(() => {
    fetch('https://countriesnow.space/api/v0.1/countries/codes')
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw new Error(data.msg);
        }
        const codes = data.data.map(country => ({
          name: country.name,
          code: country.dial_code,
        })).filter(country => country.code);
        setCountryCodes(codes);
        setFormData({ ...formData, ["country_code"]: "+91" });
        logToServer('info', 'fetching all Country codes')
      })
      .catch(error => {
        logToServer('error', `${error}`)
      });
  }, []);

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value)
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  function validateUsername(username) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(username)) {
      alert('Email addresses are not allowed in the username field.');
      return false;
    }

    return true;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setIsSubmitting(true);

    const combinedLength = formData.country_code.length + formData.number.length;
    if (combinedLength !== 13) {
      toast.warn("Country code and phone number combined must be exactly 13 characters");
      setIsSubmitting(false);
      return;
    }

    if (!formData.fname || !formData.lname || !formData.username || !formData.number || !formData.member_email || !formData.password || !formData.cpassword || !formData.country_code) {
      toast.warn("Please enter all fields");
      setIsSubmitting(false);
      return;
    }
    if (!validateUsername(formData.username)) {
      return
    }
    if (!passwordRegex.test(formData.password) ||
      formData.password.length < 8 ||
      !/[A-Z]/.test(formData.password) ||
      !/[a-z]/.test(formData.password) ||
      !/\d/.test(formData.password) || !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(formData.password)) {
      toast.warn("Password should contain at least one uppercase letter, one lowercase letter, one digit, atleast one special character and be at least 8 characters long..");
      setLoader(false);
      setIsSubmitting(false);
      return;
    }

    if (formData.password !== formData.cpassword) {
      toast.error('Passwords are not matching');
      setLoader(false);
      setIsSubmitting(false);
      return;
    }
    if (formData.type === "org") {
      if (!formData.org_name || !formData.org_website || !formData.org_add || !formData.org_plan || !formData.org_plan === "") {
        toast.warn("Please enter Organization Details")
        setLoader(false)
        setIsSubmitting(false)
        return
      }
    }
    const formDataFields = new FormData();
    for (const key in formData) {
      formDataFields.append(key, formData[key]);
    }

    const response = await fetch(`${HOST}/sign-up/`, {
      method: "POST",
      credentials: 'include',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': await getCsrfToken(),
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      if (responseData.success) {
        toast.success("User Registered Successfully");
        logToServer('info', 'User Register Successfully')
        try {
          await new Promise(move => setTimeout(move, 2000));
          navigate("/login");
        } catch (error) {
          logToServer('error', `${error}`)
        }
        return;
      } else {
        logToServer('error', 'Registration Failed ')
      }
    } else if (response.status === 400) {
      const data = await response.json();
      if (data.error === 'Email already registered') {
        toast.error("Email already registered");

      }
      else if (data.error === 'Number already registered') {
        toast.error("Number already registered");
      }
      else if (data.error === 'Username already registered') {
        toast.error("Username already registered");
      } else if (data.error === 'Enter 8 characters long Password') {
        toast.warn("Enter 8 characters long Password");
      } else {
        logToServer('error', `${data.error}`)
      }
    } else {
      logToServer('error', 'Registration Failed ')
    }
    setLoader(false);
    setIsSubmitting(false);
  };

  return (
    <div className="main">
      <div className="left-side">
        {/* <MapboxExample /> */}
        <video src={V1} autoPlay loop muted />
      </div>
      <div className="right-side">
        <div className="logo">
          <img src={`${process.env.PUBLIC_URL}/${files}vgtlogo.png`} alt="Vasundharaa Logo" />
        </div>
        <form onSubmit={handleSubmit} className="login-form">
          <input className="mt-3" value={formData.fname}
            onChange={handleChange} type="text" placeholder="First Name" required name="fname" />
          <input value={formData.lname}
            onChange={handleChange} type="text" placeholder="Last Name" required name="lname" />
          <input value={formData.username}
            onChange={handleChange} type="text" placeholder="Username" required name="username" />
          <div
            className="phone-input"
          >
            <div style={{ flex: 1, marginRight: '5px' }}>
              <select
                name="country_code"
                value={formData.country_code}
                onChange={handleChange}
                className="country-code"
                required              
              >
                <option value="" disabled>
                  Select Country Code
                </option>
                {countryCodes.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
            </div>
            <div style={{ flex: 3 }}>
              <input
                value={formData.number}
                name="number"
                onChange={handleChange}
                type="text"
                placeholder="736528376"
                required
                maxLength={13 - formData.country_code.length}
              />
            </div>
          </div>

          <input value={formData.member_email} name="member_email"
            onChange={handleChange} type="email" placeholder="name@gmail.com" required />

          <div className="pass-container">           
              <input type={showPassword ? "text" : "password"} value={formData.password} className="type-pass"
                onChange={handleChange} placeholder="Password" required name="password" />             
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="fa fa-eye-slash" title="Hide Password"></i>
                ) : (
                  <i className="fa fa-eye" title="Show Password"></i>
                )}
              </button>
            
          </div>

          <div className="pass-container" >         
              <input type={showConfirmPassword ? "text" : "password"} value={formData.cpassword}
                name="cpassword" className="type-pass"
                onChange={handleChange} placeholder="Confirm Password" required />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <i className="fa fa-eye-slash" title="Hide Password"></i>
                ) : (
                  <i className="fa fa-eye" title="Show Password"></i>
                )}
              </button>
          </div>
          <div >
            <label style={{ width: "100%" }} className="text-white mb-3" required>Account Type:
              <input onChange={handleChange} name="type" value="user" checked={formData.type === "user"} id="user" type="radio" />Individual
              <input onChange={handleChange} id="organization" name="type" value="org" checked={formData.type === "org"} type="radio" />Organizational
            </label>

          </div>
          {formData.type === "org" && (
            <>
              <div>
                <input
                  type="text"
                  id="org_name"
                  name="org_name"
                  value={formData.org_name}
                  onChange={handleChange}
                  placeholder="Enter Organization Name"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  id="org_website"
                  name="org_website"
                  value={formData.org_website}
                  onChange={handleChange}
                  placeholder="Enter Organization Website"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  id="org_add"
                  name="org_add"
                  value={formData.org_add}
                  onChange={handleChange}

                  placeholder="Enter Organization Address"
                  required
                />
              </div>
              <div>
                <select
                  className="select-plan"
                  name="org_plan"
                  value={formData.org_plan}
                  onChange={handleChange}
                  required

                >
                  <option value="" disabled>
                    Select Plan
                  </option>
                  <option value={1} >
                    Basic
                  </option>
                  <option value={2} >
                    Advanced
                  </option>
                  <option value={3} >
                    Enterprise
                  </option>
                </select>
              </div>
            </>
          )}
          <button disabled={isSubmitting} type="submit" className="button"> {isSubmitting ? "Submitting..." : " Sign Up "}</button>
          <button onClick={() => navigate('/')} className="mt-2 button">
            Back
          </button>
          {loader && (
            <div
              style={{
                flex: '1',
                position: 'relative',
                marginLeft: '100%',
                opacity: '1',
                zIndex: '1000',
              }}
            >
              <div className="lds-dual-ring">
                <i className="fa-solid fa-globe"></i>
              </div>
            </div>
          )}

          {/* Toast Notifications */}
          <ToastContainer position="bottom-right" theme="colored" draggable={false} />
        </form>
      </div>
    </div>
    // <>
    //   <div style={{marginTop:"50px"}} className="form-div">
    //     <form
    //       onSubmit={handleSubmit}
    //       className="forml"
    //       style={{ width: '500px', padding: '40px 50px 40px' }}
    //     >
    //       {/* First Name */}
    //       <div style={{ marginBottom: '13px' }}>
    //         <input
    //           type="text"
    //           id="fname"
    // name="fname"
    // value={formData.fname}
    // onChange={handleChange}
    //           className="input"
    //           style={{
    //             fontSize: '15px',
    //             padding: '8px 12px',
    //             border: 'none', // Remove default border
    //             borderBottom: '1px solid white', // Consistent bottom border
    //             outline: 'none', // Remove default outline on focus
    //           }}
    //           placeholder="Enter first name"
    //           required
    //         />
    //       </div>

    //       {/* Last Name */}
    //       <div style={{ marginBottom: '13px' }}>
    //         <input
    //           type="text"
    //           id="lname"
    //           name="lname"
    // value={formData.lname}
    // onChange={handleChange}
    //           className="input"
    //           style={{
    //             fontSize: '15px',
    //             padding: '8px 12px',
    //             border: 'none',
    //             borderBottom: '1px solid white',
    //             outline: 'none',
    //           }}
    //           placeholder="Enter last name"
    //           required
    //         />
    //       </div>

    //       {/* Username */}
    //       <div style={{ marginBottom: '13px' }}>
    //         <input
    //           type="text"
    //           id="username"
    //           name="username"
    // value={formData.username}
    // onChange={handleChange}
    //           className="input"
    //           style={{
    //             fontSize: '15px',
    //             padding: '8px 12px',
    //             border: 'none',
    //             borderBottom: '1px solid white',
    //             outline: 'none',
    //           }}
    //           placeholder="Enter username"
    //           required
    //         />
    //       </div>

    //       {/* Phone Number */}
    // <div
    //   className="phone-input"
    //   style={{
    //     marginBottom: '13px',
    //     display: 'flex',
    //     alignItems: 'center',
    //   }}
    // >
    //   <select
    //     name="country_code"
    //     value={formData.country_code}
    //     onChange={handleChange}
    //     className="input"
    //     required
    //     style={{
    //       fontSize: '12px',
    //       width: '30%',
    //       padding: '8px 12px',
    //       border: '1px solid grey',
    //       borderRadius: '4px', // Apply rounded corners uniformly
    //       backgroundColor: '#272727',
    //       appearance: 'none',
    //       WebkitAppearance: 'none',
    //       MozAppearance: 'none',
    //       outline: 'none',
    //       cursor: 'pointer',
    //       boxSizing: 'border-box', // Ensures padding doesn't affect width
    //       marginRight: '8px', // Add some space to the right of the select
    //     }}
    //   >
    //     <option value="" disabled>
    //       Select Country Code
    //     </option>
    //     {countryCodes.map((country, index) => (
    //       <option key={index} value={country.code}>
    //         {country.name} ({country.code})
    //       </option>
    //     ))}
    //   </select>

    //   <input
    //     type="text"
    //     id="number"
    //     name="number"
    //     value={formData.number}
    //     onChange={handleChange}
    //     className="input"
    //     maxLength={13 - formData.country_code.length}
    //     style={{
    //       fontSize: '15px',
    //       padding: '8px 12px',
    //       border: 'none',
    //       borderBottom: '1px solid white',
    //       outline: 'none',
    //     }}
    //     placeholder="Enter phone number"
    //     required
    //   />
    // </div>

    //       {/* Email */}
    //       <div style={{ marginBottom: '13px' }}>
    //         <input
    //           type="email"
    //           id="email"
    //           name="member_email"
    //           value={formData.member_email}
    //           onChange={handleChange}
    //           className="input"
    //           style={{
    //             fontSize: '15px',
    //             padding: '8px 12px',
    //             border: 'none',
    //             borderBottom: '1px solid white',
    //             outline: 'none',
    //           }}
    //           placeholder="Enter email"
    //           required
    //         />
    //       </div>

    //       {/* Password */}
    // <div className="password-wrapper" style={{ marginBottom: '13px' }}>
    //   <div className="input-wrapper">
    //     <input
    //       type={showPassword ? "text" : "password"}
    //       id="password"
    //       name="password"
    //       value={formData.password}
    //       onChange={handleChange}
    //       style={{
    //         fontSize: '15px',
    //         padding: '8px 12px',
    //         border: 'none',
    //         borderBottom: '1px solid white',
    //         outline: 'none',
    //       }}
    //       placeholder="Enter password"
    //       className="input border-0"
    //       required
    //     />
    //     <button
    //       type="button"
    //       style={{ backgroundColor: "#272727" }}
    //       onClick={() => setShowPassword(!showPassword)}
    //     >
    //       {showPassword ? (
    //         <i className="fa fa-eye-slash"></i>
    //       ) : (
    //         <i className="fa fa-eye"></i>
    //       )}
    //     </button>
    //   </div>
    // </div>

    //       {/* Confirm Password */}
    //       <div className="password-wrapper" >
    //         <div className="input-wrapper">
    //           <input
    //             type={showConfirmPassword ? "text" : "password"}
    //             id="cpassword"
    //             name="cpassword"
    //             value={formData.cpassword}
    //             onChange={handleChange}
    //             style={{
    //               fontSize: '15px',
    //               padding: '8px 12px',
    //               border: 'none',
    //               borderBottom: '1px solid white',
    //               outline: 'none',
    //             }}
    //             placeholder="Confirm password"
    //             className="input border-0"
    //             required
    //           />
    //           <button
    //             type="button"
    //             style={{ backgroundColor: "#272727" }}
    //             onClick={() => setShowConfirmPassword(!showConfirmPassword)}
    //           >
    //             {showConfirmPassword ? (
    //               <i className="fa fa-eye-slash"></i>
    //             ) : (
    //               <i className="fa fa-eye"></i>
    //             )}
    //           </button>
    //         </div>
    //       </div>

    // <div style={{ marginBottom: '13px' }}>
    //           <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Account Type :</span>
    //           <div style={{display:"flex",flexDirection:"row"}}>
    //               <input type="radio" id="user" style={{ marginLeft: "1px" }} onChange={handleChange} name="type" value="user" checked={formData.type==="user"} />
    //               <label style={{ marginLeft: "5px" }} htmlFor="user"> Individual</label>
    //               </div>
    //               <div style={{display:"flex",flexDirection:"row"}}>
    //                   <input onChange={handleChange} style={{ marginLeft: "1px" }} type="radio" id="organization" name="type" value="org" checked={formData.type==="org"} />
    //                   <label style={{ marginLeft: "5px" }} htmlFor="organization"> Organization</label>
    //               </div>


    //       </div>
    // {formData.type==="org" && (
    //   <>
    //   <div style={{ marginBottom: '13px' }}>
    //   <input
    //     type="text"
    //     id="org_name"
    //     name="org_name"
    //     value={formData.org_name}
    //     onChange={handleChange}
    //     className="input"
    //     style={{
    //       fontSize: '15px',
    //       padding: '8px 12px',
    //       border: 'none',
    //       borderBottom: '1px solid white',
    //       outline: 'none',
    //     }}
    //     placeholder="Enter Organization Name"
    //     required
    //   />
    // </div>
    // <div style={{ marginBottom: '13px' }}>
    //   <input
    //     type="text"
    //     id="org_website"
    //     name="org_website"
    //     value={formData.org_website}
    //     onChange={handleChange}
    //     className="input"
    //     style={{
    //       fontSize: '15px',
    //       padding: '8px 12px',
    //       border: 'none',
    //       borderBottom: '1px solid white',
    //       outline: 'none',
    //     }}
    //     placeholder="Enter Organization Website"
    //     required
    //   />
    // </div>
    // <div style={{ marginBottom: '13px' }}>
    //   <input
    //     type="text"
    //     id="org_add"
    //     name="org_add"
    //     value={formData.org_add}
    //     onChange={handleChange}
    //     className="input"
    //     style={{
    //       fontSize: '15px',
    //       padding: '8px 12px',
    //       border: 'none',
    //       borderBottom: '1px solid white',
    //       outline: 'none',
    //     }}
    //     placeholder="Enter Organization Address"
    //     required
    //   />
    // </div>
    // <div>
    // <select
    //     name="org_plan"
    //     value={formData.org_plan}
    //     onChange={handleChange}
    //     className="input"
    //     required
    //     style={{
    //       fontSize: '12px',
    //       width: '30%',
    //       padding: '8px 12px',
    //       border: '1px solid grey',
    //       borderRadius: '4px', // Apply rounded corners uniformly
    //       backgroundColor: '#272727',
    //       appearance: 'none',
    //       WebkitAppearance: 'none',
    //       MozAppearance: 'none',
    //       outline: 'none',
    //       cursor: 'pointer',
    //       boxSizing: 'border-box', // Ensures padding doesn't affect width
    //       marginRight: '8px', // Add some space to the right of the select
    //     }}
    //   >
    //     <option value="" disabled>
    //       Select Plan
    //     </option>
    //     <option value={1} >
    //       Basic
    //     </option>
    //     <option value={2} >
    //       Advanced
    //     </option>
    //     <option value={3} >
    //       Enterprise
    //     </option>
    //   </select>
    // </div>
    //   </>
    // )}



    //       {/* Submit Button */}
    //       <button
    //         type="submit"
    //         className="login"
    //         disabled={isSubmitting}
    //         style={{ marginBottom: '13px' }}
    //       >
    //         {isSubmitting ? "Submitting..." : "SignUp"}
    //       </button>

    //       {/* Back Link */}
    //       <NavLink to="/" className="register">
    //         Back
    //       </NavLink>

    //       {/* Loader */}
    // {loader && (
    //   <div
    //     style={{
    //       flex: '1',
    //       position: 'relative',
    //       marginLeft: '100%',
    //       opacity: '1',
    //       zIndex: '1000',
    //     }}
    //   >
    //     <div className="lds-dual-ring">
    //       <i className="fa-solid fa-globe"></i>
    //     </div>
    //   </div>
    // )}

    // {/* Toast Notifications */}
    // <ToastContainer position="bottom-right" theme="colored" draggable={false} />
    //     </form>
    //   </div>
    // </>

  );
}
