import React, { useContext } from "react";
import { GlobalContext } from "../../../App";

function Inspect() {
    const {
        inspect,
        SetInspect,
        vis
    } = useContext(GlobalContext)
    return (
        <div style={{ position: vis ? "absolute" : "relative", }} className="toolscont">
            <button className="btn text-white" title="Inspector" onClick={() => SetInspect(!inspect)}
                style={{
                    zIndex: "1000", fontSize: "15px", padding: "2px 2px", width: "40px", height: "40px", borderRadius: "50%", border: "none", 
                    backgroundColor: inspect ? 'white' : 'black'
                }}><i style={{ color: inspect ? "#212529" : "white" }} className="fa-solid fa-binoculars"></i></button>
        </div>
    )
}
export default Inspect